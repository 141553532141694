<template>
  <b-card-code title="Current plan">
    <b-row>
        <b-col cols="12" md="6">
            <b-list-group>
                <b-list-group-item
                    class="flex-column align-items-start"
                >
                    <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                        Your Current Plan is Basic
                        <b-badge variant="light-success">
                            free
                        </b-badge>
                    </h5>
                    </div>
                    <b-card-text class="mb-1">
                    Active until Dec 09, 2021
                    </b-card-text>
                </b-list-group-item>

                <b-list-group-item class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                        Active until Dec 09, 2021
                    </h5>
                    </div>
                    <b-card-text class="mb-1">
                    We will send you a notification upon Subscription expiration
                    </b-card-text>
                </b-list-group-item>

                <b-list-group-item class="flex-column align-items-start">
                    <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">
                        $99 Per Month
                    </h5>
                    </div>
                    <b-card-text class="mb-1">
                    Standard plan for small to medium businesses
                    </b-card-text>
                </b-list-group-item>
            </b-list-group>
        </b-col>
        <b-col cols="12" md="6">
            <div class="content-vertical-spacing">
                <div class="progress-wrapper">
                    <b-card-text class="mb-0">
                        <b-alert
                    variant="warning"
                    show
                    > 
                    <h4 class="alert-heading">
                        <feather-icon
                        class="mr-25"
                        icon="AlertTriangleIcon"
                        />
                        We need your attention!
                    </h4>
                    </b-alert>
                    </b-card-text>
                    <b-progress
                    v-model="value"
                    max="100"
                    />
                    <b-alert
                    variant="warning"
                    show
                    > 
                    <div class="alert-body">
                        your plan requires update
                    </div>
                    </b-alert>
                </div>
                <div class="progress-wrapper">
                    <b-card-text class="mb-0 d-flex justify-content-between">
                        <h6>Days</h6>
                        <h6>4 of 30 Days</h6>
                    </b-card-text>
                    <b-progress
                    v-model="value1"
                    max="100"
                    />
                    <b-card-text class="mb-0">
                        4 days remaining until your plan requires update
                    </b-card-text>
                </div>
            </div>
        </b-col>
    </b-row>

    <b-button
      variant="dark"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Upgrade Plan
    </b-button>
    <b-button
      variant="outline-danger"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Cancel subscription
    </b-button>
  </b-card-code>
</template>

<script>
import { BRow, BCol, BBadge, BButton, BListGroup, BListGroupItem, BCardText, BProgress, BAlert } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BBadge,
    BButton,
    BListGroup,
    BListGroupItem,
    BCardText,
    BProgress,
    BAlert,
  },
  data() {
    return {
      value: 0,
      value1: 90,
    }
  },
}
</script>