<template>
  <div>
    <!-- Notifications -->
    <b-card-code
    title="Notifications"
    >
      <b-card-text>
        <span>Vivamus in suscipit nisl. Praesent rutrum vestibulum suscipit.</span>
      </b-card-text>

      <b-card-text>
        <h5>RECENT</h5>
      </b-card-text>
      <b-list-group>
        <b-list-group-item class="d-flex">
          <span class="mr-1">
            <feather-icon
              icon="CheckIcon"
              size="16"
            />
          </span>
          <span>Invoice Line - File upload successfully completed</span>
        </b-list-group-item>

        <b-list-group-item class="d-flex">
          <span class="mr-1">
            <feather-icon
              icon="AlertTriangleIcon"
              size="17"
            />
          </span>
          <span>Alert - You forgot to define something that is important</span>
        </b-list-group-item>

        <b-list-group-item class="d-flex">
          <span class="mr-1">
            <feather-icon
              icon="XIcon"
              size="16"
            />
          </span>
          <span>Error - an upload error occurred</span>
        </b-list-group-item>
        <b-list-group-item class="d-flex">
          <span>Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed condimentum congue turpis a auctor.</span>
        </b-list-group-item>
      </b-list-group>

      <template #code>
        {{ codeIcon }}
      </template>
    </b-card-code>

    <!-- Notification permision TABLE -->
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <span class="align-middle ml-50">Notification Settings</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(type)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card>

    <!-- Action Buttons -->
    <b-button
      variant="dark"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BCardText, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardCode,
    BListGroup,
    BCardText,
    BListGroupItem,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {

    const permissionsData = [
      {
        type: 'New for you',
        email: true,
        browser: false,
        app: false,
      },
      {
        type: 'Account activity',
        email: false,
        browser: true,
        app: false,
      },
      {
        type: 'A new browser used to sign in',
        email: true,
        browser: false,
        app: true,
      },
      {
        type: 'A new device is linked',
        email: false,
        browser: false,
        app: false,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      permissionsData,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>